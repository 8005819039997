import { User } from 'firebase/auth'
import { QuerySnapshot } from 'firebase/firestore'
import create from 'zustand'
import { PANASReport } from '../pages/PANAS'
import Doctor from '../types/Doctor'
import FirestoreUser, { StageStatus } from '../types/FirestoreUser'
import { VitalStats } from '../types/MonthlyReport'
import { Answer } from '../types/Questionnaire'
import { Regimen } from '../types/Regimen'
import { Stage } from '../types/Stage'
import { StoolQuestionnaire } from '../types/StoolQuestionnaire'
import { EmotionalSupportPerson, MedicalSupportMonth } from '../types/support'

export type LocalConfig = {
  [docId: string]: {
    [itemId: string]: string
  }
}

export type MonthlyReport = {
  protocols?: boolean
  diets?: boolean
  physicalStrategies?: boolean
  mentalStrategies?: boolean
  supplements?: boolean
  nonSupplementMedications?: boolean
  clinicalObservations?: string
  alsfrs?: MonthlyReportQuestionnaire
  alssq?: MonthlyReportQuestionnaire
  vitalStats?: Partial<VitalStats>
  panas?: PANASReport
  stool?: {
    stool: Partial<StoolQuestionnaire['stool']>
    urine: Partial<StoolQuestionnaire['urine']>
  }
  support?: boolean
  emotionalSupportPeople?: EmotionalSupportPerson[]
  medicalSupport?: MedicalSupportMonth
  stages?: boolean
  stageProgress?: {
    [key: string]: StageStatus
  }
  sundays?: Record<string, 'live' | 'recording' | 'none'>
  waterType?: keyof typeof waterTypes
  waterAmount?: number
  protienGrams?: number
  fatGrams?: number
  netCarbsGrams?: number
}

export const waterTypes = {
  tapUnfiltered: 'Tap Unfiltered',
  tapFiltered: 'Tap Filtered',
  wellUnfiltered: 'Well Unfiltered',
  wellFiltered: 'Well Filtered',
  bottledSpring: 'Bottled Spring',
  bottledPurified: 'Bottled Purified',
  alkaline: 'Alkaline',
  reverseOsmosis: 'Reverse Osmosis',
  other: 'Other',
}

export type MonthlyReportQuestionnaire = {
  score?: number
  answers: Record<string, Answer>
}

export type Reports = Partial<Record<string, MonthlyReport>>

export default create<{
  user?: User | null
  firestoreUser?: FirestoreUser
  config?: LocalConfig
  regimens: Regimen[]
  reports: Reports
  doctors?: QuerySnapshot<Doctor>
  stages?: Stage[]
  meta?: {
    dayOfMonthLimit: number
    omitSundays: string[]
    numBootcamps: number
    bootcampNames: string[]
  }
}>(() => {
  return {
    regimens: [],
    reports: {},
  }
})
