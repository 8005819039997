import { AddIcon, DeleteIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import {
  deleteDoc,
  deleteField,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { chain, map, orderBy, reverse } from 'lodash'
import moment from 'moment'
import { Fragment, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useCurrentReport from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useReportingForMonth from '../hooks/useReportingForMonth'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import { waterTypes } from '../store/useStore'
import {
  AmountUnit,
  DurationUnit,
  FrequencyUnit,
  Method,
  Percentage,
  Regimen,
  SelectedFields,
  Usage,
  amountUnits,
  durationUnits,
  frequencyUnits,
  methods,
  percentages,
} from '../types/Regimen'
import { contactEmail } from '../utils/contactEmail'
import formatMoment from '../utils/formatMoment'
import isRawDateValid from '../utils/isRawDateValid'
import randomId from '../utils/randomId'
import useRegimens from '../utils/useRegimens'

export default ({
  title,
  lists,
  amountEnabled,
  frequencyEnabled,
  durationEnabled,
  recordLabel,
  methodEnabled,
  percentageEnabled,
  allowUpdate = true,
  waterQuestionsEnabled,
  macroQuestionsEnabled,
  alert,
}: {
  title: string
  lists: {
    label: string
    fieldId: string
    items: { [itemId: string]: string }
    id: string
  }[]
  amountEnabled?: boolean
  frequencyEnabled?: boolean
  methodEnabled?: boolean
  durationEnabled?: boolean
  percentageEnabled?: boolean
  recordLabel: string
  allowUpdate?: boolean
  waterQuestionsEnabled?: boolean
  macroQuestionsEnabled?: boolean
  alert?: string
}) => {
  const modal = useDisclosure()
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({})
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [frequency, setFrequency] = useState('')
  const [amount, setAmount] = useState('')
  const [duration, setDuration] = useState('')
  const [method, setMethod] = useState<Method>()
  const [frequencyUnit, setFrequencyUnit] = useState<FrequencyUnit>()
  const [amountUnit, setAmountUnit] = useState<AmountUnit>()
  const [durationUnit, setDurationUnit] = useState<DurationUnit>()
  const [percentage, setPercentage] = useState<Percentage>()
  const records = useRegimens(lists[0].id)
  const [editingId, setEditingId] = useState<string>()
  const toast = useToast()
  const [confirmDelete, setConfirmDelete] = useState<{
    recordId: string
    usageIndex: number
  }>()
  const navNext = useNavigateNext()
  const deleteCancelRef = useRef<any>()
  const [confirmEndId, setConfirmEndId] = useState<string>()
  const location = useLocation()
  const { month, year } = useReportingForMonth()
  const updateReport = useUpdateReportDoc()
  const [missingVisible, setMissingVisible] = useState(false)
  const report = useCurrentReport()

  const reportingFor = location.pathname.split('/')[2]
  const reportingForStart = reportingFor + '-01'
  const reportingForEnd = reportingFor + '-31'

  const closeConfirmDelete = () => setConfirmDelete(undefined)
  const closeConfirmEnd = () => setConfirmEndId(undefined)

  function clear() {
    setStartDate('')
    setEndDate('')
    setSelectedFields({})
    setFrequency('')
    setFrequencyUnit(undefined)
    setAmount('')
    setDuration('')
    setMethod(undefined)
    setAmountUnit(undefined)
    setDurationUnit(undefined)
    setEditingId(undefined)
    setPercentage(undefined)
    setMissingVisible(false)
  }

  function onSubmit() {
    const editingRecord = editingId
      ? records.find((r) => r.id === editingId)
      : undefined

    const uid = getAuth().currentUser?.uid
    if (!uid) return

    const newRecord: Regimen = editingRecord
      ? { ...editingRecord }
      : {
          startDate,
          id: randomId(),
          primaryId: selectedFields[lists[0].fieldId],
          list: lists[0].id,
          usages: [],
          uid,
        }

    if (startDate && !isRawDateValid(startDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'You have entered an invalid Start Date. Please enter in the format YYYY-MM-DD.',
        isClosable: true,
      })
    }

    if (endDate && !isRawDateValid(endDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'You have entered an invalid End Date. Please enter in the format YYYY-MM-DD.',
        isClosable: true,
      })
    }

    if (startDate > reportingForEnd) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'Start date must be in or before the current month.',
      })
    }

    const usage: Usage = {
      fields: selectedFields,
      startDate,
    }

    if (frequencyEnabled) {
      const frequencyInt = parseInt(frequency)
      if (!frequencyUnit || isNaN(frequencyInt) || frequencyInt === 0) {
        return toast({
          status: 'error',
          title: 'Error',
          description: 'Invalid Frequency',
        })
      }
      usage.frequency = frequencyInt
      usage.frequencyUnit = frequencyUnit
    }

    if (percentageEnabled) {
      if (!percentage) {
        return toast({
          status: 'error',
          title: 'Error',
          description: 'Invalid Percentage',
        })
      }
      usage.percentage = percentage
    }

    if (amountEnabled) {
      const amountFloat = parseFloat(amount)
      if (!amountUnit || isNaN(amountFloat) || amountFloat <= 0) {
        return toast({
          status: 'error',
          title: 'Error',
          description: 'Invalid Amount',
        })
      }
      usage.amount = amountFloat
      usage.amountUnit = amountUnit
    }

    if (durationEnabled) {
      const durationInt = parseInt(duration)
      if (!durationUnit || isNaN(durationInt) || durationInt === 0) {
        return toast({
          status: 'error',
          title: 'Error',
          description: 'Invalid Duration',
        })
      }
      usage.duration = durationInt
      usage.durationUnit = durationUnit
    }

    if (methodEnabled) {
      if (!method) {
        return toast({
          status: 'error',
          title: 'Error',
          description: 'Invalid method',
        })
      }
      usage.method = method
    }

    if (
      newRecord.usages.length > 0 &&
      newRecord.usages[newRecord.usages.length - 1].startDate ===
        usage.startDate
    ) {
      newRecord.usages[newRecord.usages.length - 1] = usage
    } else newRecord.usages.push(usage)

    setDoc(doc(getFirestore(), `regimens/${newRecord.id}`), newRecord)

    clear()
    modal.onClose()
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          updateReport({
            [lists[0].id]: true,
          })
          navNext()
        }}
      >
        <Stack spacing={4}>
          <MonthlyReportHeader title={title} />
          {!!alert && <Alert status="info">{alert}</Alert>}
          {waterQuestionsEnabled && (
            <Stack>
              <FormControl isRequired>
                <SimpleGrid columns={[1, 2]}>
                  <FormLabel>What kind of water are you drinking?</FormLabel>
                  <Select
                    placeholder="Choose Type"
                    onChange={(e) => {
                      updateReport({
                        waterType:
                          (e.target.value as keyof typeof waterTypes) ||
                          undefined,
                      })
                    }}
                    value={report?.waterType || ''}
                  >
                    {map(waterTypes, (label, id) => {
                      return (
                        <option value={id} key={id}>
                          {label}
                        </option>
                      )
                    })}
                  </Select>
                </SimpleGrid>
              </FormControl>
              <FormControl isRequired>
                <SimpleGrid columns={[1, 2]}>
                  <FormLabel>
                    How many ounces of water do you drink on average per day?
                    (Integer) (1 oz = 30mL)
                  </FormLabel>
                  <Input
                    type="number"
                    value={report?.waterAmount || ''}
                    onChange={(e) => {
                      console.log('e.target.value', e.target.value)
                      if (
                        e.target.value
                          .split('')
                          .some((char) => !'1234567890'.includes(char))
                      ) {
                      } else if (e.target.value === '') {
                        // @ts-ignore
                        updateReport({ waterAmount: deleteField() })
                      } else {
                        try {
                          const waterAmount = parseFloat(e.target.value)
                          if (typeof waterAmount !== 'number') {
                            toast({
                              status: 'error',
                              title: 'Error',
                              description:
                                'Please enter a valid number, 0-200.',
                            })
                          } else if (waterAmount < 0 || waterAmount > 200) {
                            toast({
                              status: 'error',
                              title: 'Error',
                              description:
                                'Please enter a valid number, 0-200.',
                            })
                          } else {
                            updateReport({
                              waterAmount,
                            })
                          }
                        } catch (e) {
                          toast({
                            status: 'error',
                            title: 'Error',
                            description: 'Please enter a valid number, 0-200.',
                          })
                        }
                      }
                    }}
                  />
                </SimpleGrid>
              </FormControl>
            </Stack>
          )}
          {macroQuestionsEnabled && (
            <Stack>
              <Box height="2" />
              <Text>
                We ask that you enter all food and beverages for ONE typical day{' '}
                <Tooltip label="Net Carbohydrates = Total grams Carbohydrates minus grams fiber. To calculate: Carb Manager App, My fitness Pal, Chronometer or other free App.">
                  <QuestionIcon color="gray.400" />
                </Tooltip>
              </Text>
              <Text fontStyle="italic">On a typical day this month I ate:</Text>

              <FormControl isRequired>
                <SimpleGrid columns={[1, 2]}>
                  <FormLabel>Grams of Protien</FormLabel>
                  <Input
                    type="number"
                    value={report?.protienGrams || ''}
                    onChange={(e) =>
                      updateReport({ protienGrams: parseFloat(e.target.value) })
                    }
                  />
                </SimpleGrid>
              </FormControl>

              <FormControl isRequired>
                <SimpleGrid columns={[1, 2]}>
                  <FormLabel>Grams of Fat</FormLabel>
                  <Input
                    type="number"
                    value={report?.fatGrams || ''}
                    onChange={(e) =>
                      updateReport({ fatGrams: parseFloat(e.target.value) })
                    }
                  />
                </SimpleGrid>
              </FormControl>

              <FormControl isRequired>
                <SimpleGrid columns={[1, 2]}>
                  <FormLabel>Grams of Net Carbohydrates</FormLabel>
                  <Input
                    type="number"
                    value={report?.netCarbsGrams || ''}
                    onChange={(e) =>
                      updateReport({
                        netCarbsGrams: parseFloat(e.target.value),
                      })
                    }
                  />
                </SimpleGrid>
              </FormControl>
            </Stack>
          )}
          <Box>
            <Button
              rightIcon={<AddIcon />}
              onClick={() => {
                modal.onOpen()
              }}
            >
              Add {recordLabel}
            </Button>
          </Box>
          {!!records.find((r) => !r.endDate) && (
            <Alert status="info">
              <AlertIcon />
              Need to update or remove one of these records? Scroll to the right
              of the table until you see these options:
              <Box height={30} width={80}>
                <Image
                  src="/regimen-options.png"
                  height={30}
                  width={80}
                  objectFit="contain"
                />
              </Box>
            </Alert>
          )}
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  {lists.map((list) => {
                    return <Th key={`th-${list.fieldId}`}>{list.label}</Th>
                  })}
                  {percentageEnabled && <Th>Percentage</Th>}
                  {amountEnabled && <Th>Amount</Th>}
                  {durationEnabled && <Th>Duration</Th>}
                  {frequencyEnabled && <Th>Frequency</Th>}
                  {methodEnabled && <Th>Method</Th>}
                  <Th>Start</Th>
                  <Th>End</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderBy(
                  records,
                  (record) => {
                    const list = lists[0]
                    return list.items[record.primaryId]
                  },
                  'asc'
                ).map((record) => {
                  const displayUsages = reverse([...record.usages])
                  return (
                    <Fragment key={record.id}>
                      {displayUsages.map((usage, usageIndex) => {
                        return (
                          <Tr key={record.id + '-' + usageIndex}>
                            {lists.map((list) => {
                              return (
                                <Td key={`${record.id}-${list.fieldId}`}>
                                  {list.items[usage.fields[list.fieldId]]}
                                </Td>
                              )
                            })}
                            {percentageEnabled && (
                              <Td>
                                {usage.percentage &&
                                  percentages[usage.percentage]}
                              </Td>
                            )}
                            {amountEnabled && (
                              <Td>
                                {usage.amount}{' '}
                                {usage.amountUnit &&
                                  amountUnits[usage.amountUnit]}
                              </Td>
                            )}
                            {durationEnabled && (
                              <Td>
                                {usage.duration}{' '}
                                {usage.durationUnit &&
                                  durationUnits[usage.durationUnit]}
                              </Td>
                            )}
                            {frequencyEnabled && (
                              <Td>
                                {usage.frequency}x/
                                {usage.frequencyUnit &&
                                  frequencyUnits[usage.frequencyUnit]}
                              </Td>
                            )}
                            {methodEnabled && (
                              <Td>
                                {usage.method ? methods[usage.method] : 'N/A'}
                              </Td>
                            )}
                            <Td>
                              {usage.startDate
                                ? formatMoment(moment(usage.startDate))
                                : `Before ${month}/${year}`}
                            </Td>
                            <Td>
                              {usageIndex === 0
                                ? record.endDate
                                  ? formatMoment(moment(record.endDate))
                                  : `Current`
                                : moment(
                                    displayUsages[usageIndex - 1].startDate
                                  ).format('l')}
                            </Td>
                            <Td>
                              <Stack direction="row" spacing={1}>
                                {usageIndex === 0 && !record.endDate && (
                                  <>
                                    {allowUpdate && (
                                      <Button
                                        size="sm"
                                        onClick={() => {
                                          const usage =
                                            record.usages[
                                              record.usages.length - 1
                                            ]
                                          setEditingId(record.id)
                                          setStartDate(usage.startDate)
                                          setFrequency(
                                            usage.frequency?.toString() || ''
                                          )
                                          setFrequencyUnit(usage.frequencyUnit)
                                          setAmount(
                                            usage.amount?.toString() || ''
                                          )
                                          setAmountUnit(usage.amountUnit)
                                          setMethod(usage.method)
                                          setSelectedFields(usage.fields)
                                          setDuration(
                                            usage.duration?.toString() || ''
                                          )
                                          setDurationUnit(usage.durationUnit)
                                          setPercentage(usage.percentage)

                                          modal.onOpen()
                                        }}
                                      >
                                        Update
                                      </Button>
                                    )}
                                    <Button
                                      size="sm"
                                      colorScheme="red"
                                      onClick={() => {
                                        setConfirmEndId(record.id)
                                      }}
                                    >
                                      Stop
                                    </Button>
                                  </>
                                )}
                                {usage.startDate >= reportingForStart && (
                                  <IconButton
                                    aria-label="Delete"
                                    ml={1}
                                    size="sm"
                                    colorScheme="red"
                                    variant="outline"
                                    onClick={() => {
                                      setConfirmDelete({
                                        recordId: record.id,
                                        usageIndex,
                                      })
                                    }}
                                    icon={<DeleteIcon />}
                                  />
                                )}
                              </Stack>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </form>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <ModalHeader>
            {editingId ? 'Edit' : 'Add'} {recordLabel}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              {lists.map((list, index) => {
                return (
                  <FormControl key={list.fieldId} isRequired>
                    <FormLabel>{list.label}</FormLabel>
                    <Select
                      disabled={!!editingId && index === 0}
                      required
                      value={selectedFields[list.fieldId] || ''}
                      onChange={(e) => {
                        setSelectedFields({
                          ...selectedFields,
                          [list.fieldId]: e.target.value,
                        })
                      }}
                      placeholder={`Choose ${list.label}`}
                    >
                      {chain(list.items)
                        .map((label, id) => {
                          return { label, id }
                        })
                        .orderBy((item) => item.label.toLowerCase(), 'asc')
                        .value()
                        .map(({ id, label }) => {
                          return (
                            <option key={id} value={id}>
                              {label}
                            </option>
                          )
                        })}
                    </Select>
                    {missingVisible ? (
                      <Text fontSize="xs">
                        If your option is missing, please message us at{' '}
                        {contactEmail} and we will get it added to the list.
                      </Text>
                    ) : (
                      <Button
                        size="xs"
                        variant="link"
                        onClick={() => setMissingVisible(true)}
                      >
                        Missing?
                      </Button>
                    )}
                  </FormControl>
                )
              })}

              <FormControl isRequired>
                <FormLabel>
                  {editingId ? 'What date did you change?' : 'Start Date'}{' '}
                  (YYYY-MM-DD)
                </FormLabel>
                <Input
                  required
                  placeholder="YYYY-MM-DD"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>
              {percentageEnabled && (
                <FormControl isRequired>
                  <FormLabel>Percentage of Protocol followed</FormLabel>
                  <RadioGroup
                    value={percentage}
                    onChange={(value) => {
                      setPercentage(value as Percentage)
                    }}
                  >
                    <HStack spacing={2}>
                      {map(percentages, (label, percentage) => {
                        return (
                          <Radio key={percentage} value={percentage}>
                            {label}
                          </Radio>
                        )
                      })}
                    </HStack>
                  </RadioGroup>
                </FormControl>
              )}
              {amountEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Amount</FormLabel>
                    <Input
                      required
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value)
                      }}
                      step="any"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Unit</FormLabel>
                    <Select
                      required
                      value={amountUnit || ''}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setAmountUnit(e.target.value as AmountUnit)
                      }}
                    >
                      {chain(amountUnits)
                        .map((title, value) => {
                          return {
                            title,
                            value: value as AmountUnit,
                          }
                        })
                        .orderBy((item) => item.title.toLowerCase(), 'asc')
                        .map(({ title, value }) => {
                          return (
                            <option value={value} key={value}>
                              {title}
                            </option>
                          )
                        })
                        .value()}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {durationEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Duration</FormLabel>
                    <Input
                      required
                      type="number"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value)
                      }}
                      step="any"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Unit</FormLabel>
                    <Select
                      required
                      value={durationUnit || ''}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setDurationUnit(e.target.value as DurationUnit)
                      }}
                    >
                      {map(durationUnits, (title, value) => {
                        return (
                          <option key={value} value={value}>
                            {title}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {frequencyEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Frequency</FormLabel>
                    <InputGroup>
                      <Input
                        required
                        type="number"
                        value={frequency}
                        onChange={(e) => {
                          setFrequency(e.target.value)
                        }}
                        step="any"
                      />
                      <InputRightAddon>x</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Per</FormLabel>
                    <Select
                      required
                      value={frequencyUnit || ''}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setFrequencyUnit(e.target.value as FrequencyUnit)
                      }}
                    >
                      {map(frequencyUnits, (title, value) => {
                        return (
                          <option key={value} value={value}>
                            {title}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {methodEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Method</FormLabel>
                    <Select
                      required
                      value={method}
                      placeholder="Choose Method"
                      onChange={(e) => {
                        setMethod(e.target.value as Method)
                      }}
                    >
                      {chain(methods)
                        .map((title, value) => {
                          return { title, value }
                        })
                        .orderBy('title', 'asc')
                        .map(({ title, value }) => {
                          return (
                            <option key={value} value={value}>
                              {title}
                            </option>
                          )
                        })
                        .value()}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => {
                modal.onClose()
                clear()
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button size="sm" type="submit">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={!!confirmDelete}
        leastDestructiveRef={deleteCancelRef}
        onClose={closeConfirmDelete}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove {recordLabel}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to remove this item?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="gray"
                ref={deleteCancelRef}
                onClick={closeConfirmDelete}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  if (confirmDelete) {
                    const _record = records.find(
                      (r) => r.id === confirmDelete.recordId
                    )

                    if (_record) {
                      const record = { ..._record }
                      record.usages.splice(confirmDelete.usageIndex, 1)

                      const ref = doc(getFirestore(), `regimens/${record.id}`)

                      if (record.usages.length === 0) {
                        deleteDoc(ref)
                      } else {
                        setDoc(ref, record)
                      }
                    }
                  }

                  setConfirmDelete(undefined)
                }}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={!!confirmEndId} onClose={closeConfirmEnd}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Stop {recordLabel}</ModalHeader>

          <ModalBody>
            <Stack>
              <Text>On what date did you stop? (YYYY-MM-DD)</Text>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input
                  placeholder="YYYY-MM-DD"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" onClick={closeConfirmEnd}>
              Cancel
            </Button>
            <Button
              disabled={!endDate}
              colorScheme="red"
              onClick={() => {
                if (!endDate) return

                if (endDate > reportingForEnd) {
                  return toast({
                    status: 'error',
                    title: 'Error',
                    description: 'End date must be in or before current month.',
                  })
                }

                const recordToEnd = records.find((r) => r.id === confirmEndId)
                if (!recordToEnd) {
                  return toast({
                    status: 'error',
                    title: 'Error',
                    description: 'Could not find record to end.',
                  })
                }

                if (
                  endDate <
                  recordToEnd.usages[recordToEnd.usages.length - 1].startDate
                ) {
                  return toast({
                    status: 'error',
                    title: 'Error',
                    description: 'End date must be after the start date.',
                  })
                }

                updateDoc(doc(getFirestore(), `regimens/${confirmEndId}`), {
                  endDate,
                })
                setConfirmEndId(undefined)
                setEndDate('')
              }}
              ml={3}
            >
              Stop
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
